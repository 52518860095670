import Plausible from "plausible-tracker";

export default class THCAnalytics {
  private static plausible: ReturnType<typeof Plausible> | null = null;

  static getPlausible() {
    if (THCAnalytics.plausible === null) {
      const plausible = Plausible({
        domain: 'tryonhaulcentral.com',
        apiHost: 'https://straw.tryonhaulcentral.com',
        trackLocalhost: false,
      })
      plausible.enableAutoPageviews()
      plausible.enableAutoOutboundTracking()

      THCAnalytics.plausible = plausible
    }

    return THCAnalytics.plausible
  }
}
