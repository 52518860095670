import Alpine from 'alpinejs';
import { PostsService } from '../services/PostService';
import { CreatorsService } from '../services/CreatorService';
import { API_BASE_URL, AVATAR_CDN_BASE_URL, CDN_BASE_URL, THUMBNAIL_CDN_BASE_URL } from '../constants';
import { ApiClient } from '../services/ApiService';
import { getAuth } from 'firebase/auth'
import { MediaRemoteControl } from 'vidstack';
import 'vidstack/player';
import 'vidstack/player/layouts/default';
import 'vidstack/player/ui';
import THCAnalytics from './THCAnalytics';
import { mmaiRedirectModal } from '../components/ModalMeAIRedirectModal';

export enum AdInsertType {
  MODELMEAI,
  CONCEALMENTVPN,
}

export const getCommonAlpineData = (apiClient: ApiClient) => {

  Alpine.store('authModal', {
    isOpen: false,
    pendingAction: null,
  })

  Alpine.store('mmaiRedirectModal', mmaiRedirectModal())

  Alpine.store('saveProfilePopup', {
    isOpen: false,
  })

  Alpine.store('notificationModal', {
    isOpen: false,
    isAnimating: false,
    step: 'options', // options, telegram, email
    creatorId: null,
    email: '',

    telegramStartLink: '',
    isSubscribedToTelegram: false,

    async open(creatorId: string) {
      // disable for now until the copy and stuff is finalized
      return
      this.creatorId = creatorId;

      const lastShown = localStorage.getItem('telegram_modal_last_shown');
      const oneWeek = 7 * 24 * 60 * 60 * 1000; // one week in milliseconds

      if (lastShown) {
        const timeSinceLastShown = Date.now() - parseInt(lastShown);
        if (timeSinceLastShown < oneWeek) {
          // If less than a week has passed, don't show the modal
          return;
        }
      }

      this.step = 'options';
      this.isOpen = true;

      // Check telegram subscription status
      try {
        const status = await apiClient.getTelegramSubscriptionStatus();
        this.isSubscribedToTelegram = status.is_subscribed;

        if (!status.is_subscribed) {
          // Get telegram start link if not subscribed
          this.telegramStartLink = await apiClient.getTelegramStartLink();
          localStorage.setItem('telegram_modal_last_shown', Date.now().toString());
        }
      } catch (error) {
        console.error('Error checking telegram status:', error);
      }
    },

    close() {
      this.isAnimating = true;
      setTimeout(() => {
        this.isOpen = false;
        this.isAnimating = false;
        this.step = 'options';
        this.email = '';
        this.telegramStartLink = '';
      }, 300);
    },

    handeClickTelegram() {
      THCAnalytics.getPlausible()?.trackEvent('click-thc-telegram')

      this.close()
    }

  });

  Alpine.store('currentPostId', {
    currentPostIds: [],
  })

  Alpine.data('common', function() {
    const postsService = new PostsService();
    const creatorsService = CreatorsService.getInstance();

    const feedClient = new ApiClient({
      baseUrl: API_BASE_URL,
      // Optional custom domain for cookies
      //cookieDomain: '.tryonhaulcentral.com',
      // Optional custom error handler
      onError: (error) => {
        console.error('Feed client error:', error);
      }
    });

    return {
      CDN_BASE_URL,
      THUMBNAIL_CDN_BASE_URL,
      AVATAR_CDN_BASE_URL,

      // Services
      postsService,
      creatorsService,

      // State
      darkMode: localStorage.getItem('darkMode') === 'true' || true,
      autoScroll: localStorage.getItem('autoScroll') === 'true' || false,
      wasAutoScroll: false,
      scrollSpeed: localStorage.getItem('scrollSpeed') || 'normal',
      settingsPanelOpen: false,
      scrollInterval: null,

      currentVideoId: null as string | null,

      isLoadingCreatorPosts: false,

      viewedPostIds: [] as string[],

      lastLeftTap: 0,
      lastRightTap: 0,

      followedCreators: [] as string[],
      followsLoaded: false,

      viewedVideoCount: 0,
      loadTimeMs: Date.now(),

      skipsAndRewinds: {},

      adInsertSrc: null as string | null,

      adInsertAlt: null as string | null,

      async initCommon() {
        THCAnalytics.getPlausible()

        const cu = await this.getCurrentUser()
        if (cu) {
          await this.loadFollows();
        }

        getAuth().onAuthStateChanged((user) => {
          if (user) {
            this.loadFollows();
          }
        })

        const lang = navigator.language
        if (lang) {
          THCAnalytics.getPlausible()?.trackEvent('identify_nav_lang', {
            props: {
              lang,
            }
          })
        }

        const referrer = document.referrer.toLowerCase()

        const isDev = window.location.host.includes('localhost:')
        const isDuckDuckGoReferrer = referrer.includes('duckduckgo')
        const creator = 'roselie'
        const isRoselie = window.location.pathname.includes('/creator/roselie-arritola')

        /*
        if (isRoselie) {
          this.adInsertSrc = `/static/images/modelmeai-native-en-${creator}.gif`
          this.adInsertCopy = 'check out modelmeai.com. the ai-powered clothing try on image editing platform'
        }
        */
        this.adInsertSrc = '/static/images/concealmentvpn-ad-1.webp'
        this.adInsertAlt = 'check out concealmentvpn.com. the VPN and privacy suite for taboo content.'
        this.adInsertType = AdInsertType.CONCEALMENTVPN
      },

      handleAdInsertClick(attribution: string) {
        if (this.adInsertType === AdInsertType.MODELMEAI) {
          this.$store.mmaiRedirectModal.startRedirect(
            'native',
            attribution,
            'thc',
            this.adInsertType,
          )
        } else if (this.adInsertType === AdInsertType.CONCEALMENTVPN) {
          this.$store.mmaiRedirectModal.startRedirect(
            'native',
            attribution,
            'thc',
            this.adInsertType,
          )
        }
      },

      // Settings handlers
      toggleDarkMode() {
        this.darkMode = !this.darkMode;
        localStorage.setItem('darkMode', String(this.darkMode));
      },

      toggleAutoScroll() {
        this.autoScroll = !this.autoScroll;
        localStorage.setItem('autoScroll', String(this.autoScroll));
        this.syncAutoScroll();
      },

      updateScrollSpeed() {
        localStorage.setItem('scrollSpeed', this.scrollSpeed);
        this.syncAutoScroll();
      },

      syncAutoScroll() {
        if (this.scrollInterval) {
          clearInterval(this.scrollInterval);
        }

        if (this.autoScroll) {
          const speeds = {
            slow: 1,
            normal: 2,
            fast: 4
          };

          this.scrollInterval = setInterval(() => {
            window.scrollBy({
              top: speeds[this.scrollSpeed],
              behavior: 'smooth'
            });
          }, 50);
        }
      },

      dedupeTags(tags: string[]): string[] {
        return Array.from(new Set(tags));
      },

      unloadVideo(video: HTMLVideoElement) {
        /*
        for (let i = 0; i < video.children.length; i++) {
          const child = video.children.item(i)
          if (child) {
            const src = child.getAttribute('src')
            if (src) {

              //video.pause()
              //child.setAttribute('data-src', src)
              //child.setAttribute('src', '')
              //video.load()
            }
          }
        }
        */
      },

      async handleVideoSeek(video: HTMLVideoElement, trigger: 'started' | 'ended') {
        const time = video.currentTime;
        if (time < 0.2) {
          return;
        }

        const post_id = video.parentElement?.getAttribute('data-post-id');
        const creator_id = video.getAttribute('data-creator-id');

        if (post_id && creator_id) {
          const event = trigger === 'started' ? 'seek_start' : 'seek_end';
          THCAnalytics.getPlausible()?.trackEvent(event, {
            props: {
              post_id,
              time: time.toFixed(1),
            }
          });
        }
      },

      async handleVideoSkip(el: HTMLDivElement, zone: 'left' | 'right') {
        const mediaPlayer = el.parentElement?.querySelector('media-player');
        if (!mediaPlayer) {
          console.warn('failed to find video for skip');
          return;
        }

        const remote = new MediaRemoteControl();
        const video = remote.getPlayer(mediaPlayer?.querySelector('source'));
        const now = Date.now();

        if (!video) {
          console.log("no video found in handleVideoSkip")
          return
        }

        let startTime = video.currentTime;
        let endTime;
        let isDoubleTap = false

        if (zone === 'left') {
          if (now - this.lastLeftTap < 400) { // Double-tap threshold: 400ms
            endTime = Math.max(video.currentTime - 5, 0);
            video.currentTime = endTime; // Skip backward 5s
            isDoubleTap = true
          }
          this.lastLeftTap = now;
        } else {
          if (now - this.lastRightTap < 400) { // Double-tap threshold: 400ms
            endTime = Math.min(video.currentTime + 5, video.duration);
            video.currentTime = endTime; // Skip forward 5s
            isDoubleTap = true
          }
          this.lastRightTap = now;
        }

        // Track skip/rewind actions
        const post_id = el.parentElement?.getAttribute('data-post-id');
        const creator_id = el.parentElement?.querySelector('media-player')?.getAttribute('data-creator-id');

        if (post_id && creator_id && isDoubleTap) {
          if (!this.skipsAndRewinds[post_id]) {
            this.skipsAndRewinds[post_id] = [];
          }
          this.skipsAndRewinds[post_id].push({
            action_type: zone === 'left' ? 'rewind' : 'skip',
            start_time: startTime,
            end_time: endTime,
          });

          THCAnalytics.getPlausible()?.trackEvent('skip', {
            props: {
              direction: zone === 'left' ? 'back' : 'forward',
              time: startTime.toFixed(1),
            }
          });
        }
      },

      observer: new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        entries.forEach(async (entry: IntersectionObserverEntry) => {
          if (!entry.isIntersecting) {
            const mediaPlayer = entry.target.querySelector('media-player');
            if (mediaPlayer) {
              const postId = mediaPlayer.parentElement?.getAttribute('data-post-id');
              const remote = new MediaRemoteControl();
              const video = remote.getPlayer(mediaPlayer.querySelector('source'));

              if (video) {
                const time = video.currentTime;
                if (time > 1) {
                  const position = mediaPlayer.parentElement?.getAttribute('data-video-index');
                  const creatorId = mediaPlayer.getAttribute('data-creator-id');
                  const feedType = mediaPlayer.getAttribute('data-feed-type') || 'main';

                  const skipsAndRewinds = this.$data.skipsAndRewinds[postId] || [];

                  feedClient.recordView({
                    post_id: postId,
                    watch_time_seconds: parseInt(time.toFixed(0)),
                    feed_position: position ? parseInt(position) : 0,
                    creator_id: creatorId || '',
                    feed_source: feedType,
                    skips_and_rewinds: skipsAndRewinds,
                  });

                  const seen = this.$data.viewedPostIds.includes(postId);
                  this.$data.viewedPostIds.push(postId);

                  if (!seen) {
                    THCAnalytics.getPlausible()?.trackEvent('video_view', {
                      props: {
                        post_id: postId,
                        creator_id: creatorId,
                        feed_source: feedType,
                        feed_position: position,
                      }
                    });
                  }

                  delete this.$data.skipsAndRewinds[postId];
                }

                await video.pause();
                video.currentTime = 0;
                const videoEl = video.el?.querySelector('video');
                if (videoEl) {
                  videoEl.pause();
                  videoEl.currentTime = 0;
                }
              }

              if (postId) {
                this.$store.currentPostId.currentPostIds = this.$store.currentPostId.currentPostIds.filter((id: string) => id !== postId);
              }
            }

            this.$data.observer.unobserve(entry.target);
          }
        });
      }, {
        root: null,
        threshold: 0,
      }),

      // TODO: fix that this doesn't record the view if they land on a video and dont scroll
      async handleVideoIntersect(mediaPlayerWrapperEl: HTMLElement, isIntersecting: boolean = true, feedSource: 'main' | 'profile' | 'search' | 'tag' | 'direct' = 'main', postId: string, index: number = 0) {

        this.observer.observe(mediaPlayerWrapperEl);
        const oneSecondHasPassedSinceLoad = Date.now() - this.loadTimeMs > 1000

        // TODO: this is a bit of a hack and we may not need it anymore
        if (index < 2 || oneSecondHasPassedSinceLoad) {
          if (!this.$store.currentPostId.currentPostIds.includes(postId)) {
            this.$store.currentPostId.currentPostIds.push(postId)

            setTimeout(() => this.handleVideoIntersect(mediaPlayerWrapperEl, true, feedSource, postId, index), 100)
            return
          }
        }

        const mediaPlayerEl = mediaPlayerWrapperEl.querySelector('media-player')
        if (!mediaPlayerEl) {
          console.warn('handleVideoIntersect called on element that doesnt container media-player')
          return
        }

        // load next video
        const indexRaw = mediaPlayerWrapperEl.getAttribute('data-video-index')
        if (indexRaw) {
          const index = parseInt(indexRaw)
          const nextMediaPlayerWrapperEl = document.querySelector('[data-video-index="' + (index + 1).toString() + '"]')
          if (nextMediaPlayerWrapperEl) {
            const nextPostId = nextMediaPlayerWrapperEl.getAttribute('data-post-id')
            if (nextPostId) {
              this.$store.currentPostId.currentPostIds.push(nextPostId)
            }
          }
        }

        const videoId = mediaPlayerEl.id.replace('video-', '');

        const remote = new MediaRemoteControl()
        const video = remote.getPlayer(mediaPlayerEl.querySelector('source'))
        if (!video) {
          console.warn('no media player found from target', mediaPlayerEl.querySelector('source'))
          return
        }

        this.currentVideoId = videoId;
        if (!this.$store.currentPostId.currentPostIds.includes(videoId)) {
          this.$store.currentPostId.currentPostIds = [...this.$store.currentPostId.currentPostIds, videoId];
        }

        // Ensure video is muted first for autoplay to work
        if (video.el) {
          video.muted = true;
          const videoEl = video.el.querySelector('video')
          if (videoEl) {
            videoEl.setAttribute('muted', '')
            videoEl.muted = true
            videoEl.defaultMuted = true
            videoEl.currentTime = 0
          }
        }

        try {
          // Reset video position
          video.currentTime = 0;
          // Try to play immediately
          if (video.state.canPlay) {
            if (video.el) {
              const videoEl = video.el.querySelector('video')
              if (videoEl) {
                setTimeout(() => {
                  videoEl.currentTime = 0
                }, 100)
              }
            }

            await video.play();

          } else {
            video.addEventListener('can-play', async () => {
              if (video.el) {
                const videoEl = video.el.querySelector('video')
                if (videoEl) {
                  setTimeout(() => {
                    videoEl.currentTime = 0
                  }, 100)
                }
              }

              await video.play().catch(() => {

                setTimeout(() => {
                  video.play()
                }, 100)
              })

            })
          }
          // Optionally unmute after successful autoplay (if desired)
          // video.muted = false;
        } catch (error) {
          console.warn('Error autoplaying video:', error);
        }
      },

      isMobileScreen() {
        return window.innerWidth <= 768;
      },

      async checkVideoCount() {
        // Get video count from localStorage or initialize it
        this.viewedVideoCount = parseInt(localStorage.getItem('viewedVideoCount') || '0');

        // Increment video count
        this.viewedVideoCount++;
        localStorage.setItem('viewedVideoCount', this.viewedVideoCount.toString());

        // Show prompt after 10 videos if not previously dismissed and not logged in
        if (this.viewedVideoCount >= 10 && !localStorage.getItem('promptDismissed')) {
          const cu = await this.getCurrentUser()
          if (!cu) {
            this.$store.saveProfilePopup.isOpen = true;
          }
        }
      },

      async handleVideoEnded(video: HTMLVideoElement, feedSource: 'main' | 'profile' | 'search' | 'tag' | 'direct' = 'main') {
        const postId = video.id.replace('video-', '');
        const time = video.currentTime
        const position = video.parentElement?.getAttribute('data-video-index')
        const creatorId = video.getAttribute('data-creator-id')

        const skipsAndRewinds = this.$data.skipsAndRewinds[postId] || [];

        feedClient.recordView({
          post_id: postId,
          watch_time_seconds: parseInt(time.toFixed(0)),
          feed_position: position ? parseInt(position) : 0,
          creator_id: creatorId || '',
          feed_source: feedSource,
          skips_and_rewinds: skipsAndRewinds,
        })

        video.currentTime = 0
        video.play()

        const seen = this.viewedPostIds.includes(postId);
        this.viewedPostIds.push(postId);

        if (!seen) {
          THCAnalytics.getPlausible()?.trackEvent('video_completed', {
            props: {
              post_id: postId,
              creator_id: creatorId,
              feed_source: feedSource,
              feed_position: position,
            }
          });
        }
      },

      unescapeHTML(str: string) {
        const textarea = document.createElement('textarea');
        textarea.innerHTML = str;
        return textarea.value;
      },

      secondsToISO8601(durationSeconds: number) {
        // Convert nanoseconds to milliseconds
        const milliseconds = durationSeconds / 1e6;

        // Convert to total seconds
        const totalSeconds = Math.floor(milliseconds / 1000);

        // Calculate hours, minutes, and seconds
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        // Build the ISO 8601 string
        let result = "PT";
        if (hours > 0) result += `${hours}H`;
        if (minutes > 0) result += `${minutes}M`;
        if (seconds > 0 || result === "PT") result += `${seconds}S`; // Include seconds if empty

        return result;
      },

      enableSmoothScroll() {
        const container = this.$refs.feedContainer;
        if (!container) return;

        container.addEventListener('wheel', (e: WheelEvent) => {
          e.preventDefault();

          const delta = e.deltaY;
          const currentScroll = container.scrollTop;
          const direction = delta > 0 ? 1 : -1;

          const viewportHeight = container.clientHeight;
          const currentIndex = Math.round(currentScroll / viewportHeight);
          const targetIndex = currentIndex + direction;
          const targetScroll = targetIndex * viewportHeight;

          container.scrollTo({
            top: targetScroll,
            behavior: 'smooth'
          });
        }, { passive: false });
      },

      async handleLike(postId: string, isLike: boolean) {
        const cu = await this.getCurrentUser()
        if (!cu) {
          this.$store.authModal.isOpen = true;
          this.$store.authModal.pendingAction = function() {
            this.handleLike(postId);
          }.bind(this)
          return;
        }

        try {
          await apiClient.recordLikeInteraction(postId, isLike);
          // Update UI state
        } catch (error) {
          console.error('Error recording like:', error);
        }
      },

      getCurrentUser() {
        return getAuth().authStateReady().then(() => getAuth().currentUser)
      },

      isLiked(postId) {
        // Implementation to check if post is liked
      },

      async loadFollows() {
        try {
          this.followedCreators = await apiClient.getFollows();
          this.followsLoaded = true;
        } catch (error) {
          console.error('Error loading follows:', error);
        }
      },

      async handleFollow(creatorId: string) {
        const cu = await this.getCurrentUser()
        if (!cu) {
          this.$store.authModal.isOpen = true;
          this.$store.authModal.pendingAction = () => this.handleFollow(creatorId);
          return;
        }

        const isFollowing = this.followedCreators.includes(creatorId);

        try {
          await apiClient.recordFollowCreatorInteraction(creatorId, !isFollowing);

          // Update local state
          if (isFollowing) {
            this.followedCreators = this.followedCreators.filter(id => id !== creatorId);
          } else {
            this.followedCreators.push(creatorId);
            // Open notification modal for new follows
            this.$store.notificationModal.open(creatorId);
          }
        } catch (error) {
          console.error('Error following creator:', error);
        }
      },

      isFollowing(creatorId: string) {
        if (this.followedCreators) {
          return this.followedCreators.includes(creatorId);
        }
      },

      async handleSaveProfile() {
        const cu = await this.getCurrentUser()
        if (!cu) {
          this.$store.authModal.isOpen = true;
        }

        this.$store.saveProfilePopup.isOpen = false;
      },

      handleMMAIClick(format: string, ref: string, source: string = 'thc') {
        THCAnalytics.getPlausible()?.trackEvent('modelmeai_ad_click', {
          props: {
            format,
          }
        })

        this.openInNewTab(`https://modelmeai.com/try-on?utm_source=${source}&utm_content=${ref}`)
      },
    }
  })
}

