import { AdInsertType } from "../utils/common";
import THCAnalytics from "../utils/THCAnalytics";

const MMAI_TITLE = 'Heading to ModelMeAI!'
const MMAI_BODY = 'You\'re navigating to modelmeai.com to swap her outfit with AI.'

const CONCEALMENTVPN_TITLE = 'Heading to ConcealmentVPN!'
const CONCEALMENTVPN_BODY = 'You\'re navigating to concealmentvpn.com to protect yourself and conceal all activity.'

export function mmaiRedirectModal() {
  return {
    showModal: false,
    timeLeft: 4,
    redirectTimer: null,
    bodyCopy: null,
    titleCopy: null,
    adInsertType: null,

    startRedirect(
      format: string,
      creatorName: string,
      source: string = 'thc',
      adInsertType: AdInsertType,
    ) {
      this.adInsertType = adInsertType

      switch (adInsertType) {
        case AdInsertType.MODELMEAI: {
          this.titleCopy = MMAI_TITLE
          this.bodyCopy = MMAI_BODY
          break
        }
        case AdInsertType.CONCEALMENTVPN: {
          this.titleCopy = CONCEALMENTVPN_TITLE
          this.bodyCopy = CONCEALMENTVPN_BODY
          break
        }
      }

      this.showModal = true
      THCAnalytics.getPlausible()?.trackEvent('modelmeai_ad_click', {
        props: {
          format,
          creatorName,
        }
      })

      this.redirectTimer = setInterval(() => {
        this.timeLeft--;
        if (this.timeLeft <= 0) {
          this.redirectNow(format, creatorName, source);
        }
      }, 1000);
    },

    startRedirectModelMeAI(format: string, attribution: string) {
      return this.startRedirect(
        format,
        attribution,
        'thc',
        AdInsertType.MODELMEAI,
      )
    },

    getRedirectUrl(
      type: AdInsertType,
      format: string,
      creatorName: string,
      source: string = 'thc',
    ) {
      const mmai = `https://modelmeai.com/try-on?source=tryonhaulcentral&utm_source=${source}&utm_content=${creatorName}&utm_term=${format}`

      switch (type) {
        case AdInsertType.MODELMEAI: {
          return mmai
        }
        case AdInsertType.CONCEALMENTVPN: {
          return `https://concealmentvpn.com/?source=tryonhaulcentral&utm_source=${source}&utm_content=${creatorName}&utm_term=${format}`
        }
        default: {
          return mmai
        }
      }
    },

    redirectNow(format: string, creatorName: string, source: string = 'thc') {
      clearInterval(this.redirectTimer);

      this.openInNewTab(this.getRedirectUrl(this.adInsertType, format, creatorName, source))
      this.showModal = false;
      this.timeLeft = 4;
    },

    cancelRedirect() {
      THCAnalytics.getPlausible()?.trackEvent('modelmeai_ad_cancel_redirect')

      clearInterval(this.redirectTimer);
      this.showModal = false;
      this.timeLeft = 4;
    },

    openInNewTab(url: string) {
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.rel = 'noopener noreferrer'; // Security best practice
      document.body.appendChild(a); // Required for some browsers
      a.click();
      document.body.removeChild(a); // Cleanup
    },

  }
}

